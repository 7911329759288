@font-face {font-family: "font-general"; src: url(../assets/fonts/WorkSans.ttf);}
@font-face {font-family: "font-nav"; src: url(../assets/fonts/Oswald-Regular.ttf);}
@font-face {font-family: "font-nom"; src: url(../assets/fonts/Poppins-Regular.ttf);}
@font-face {font-family: "font-titre"; src: url(../assets/fonts/DancingScript.ttf);}
@font-face {font-family: "font-footer-logo"; src: url(../assets/fonts/Pacifico.ttf);}
$font-general: "font-general", sans-serif;
$font-nav: "font-nav", sans-serif;
$font-nom: "font-nom", sans-serif;
$font-titre: "font-titre", sans-serif;
$font-footer-logo: "font-footer-logo", sans-serif;

body {
    background-position: 0px 0px;
    background-image: linear-gradient(180deg, #00182DFF 5%, #D7F9FFFF 100%);
    background-repeat: no-repeat;
    font-family: $font-general, Verdana;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

*
{
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 120vh;
}
.contactContainer{
    margin-top: 200px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactContainer .contactBox{
    position: relative;
}

.contactContainer .contactBox form{
    background: rgba(255, 255, 255, 0.5);
    padding-top: 3rem;
    padding-bottom: 1rem;
    border-radius: 25px;
    border-left: 1px solid rgba(255, 255, 255, .5);
    border-top: 1px solid rgba(255, 255, 255, .5);
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, .2);
    text-align: center;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    width: 550px;
}
 
.contactContainer .contactBox p{
    color: rgb(255, 255, 255);
    font-size: 500;
    opacity: .7;
    font-size: 1.4rem;
    margin-bottom: 60px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
}

.contactContainer .contactBox input, .contactContainer .contactBox textarea{
    background: transparent;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, .3);
    border-top: 1px solid rgba(255, 255, 255, .3);
    padding: 1rem;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, .2);
    font-weight: 500;
    transition: all .3s;
    outline: none;
    font-family: $font-general;

    &::placeholder{
        color: rgb(100, 100, 100);
        font-style: italic;
    }

    &:hover{
        background: rgba(255, 255, 255, .7);
        box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, .2);
    }

}

.contactContainer .contactBox input{
    width: 200px;
    margin: 10px 10px;
    border-radius: 50px;
}


.contactContainer .contactBox textarea{
    width: 450px;
    height: 300px;
    resize: none;
    margin: 10px 0;
    border-radius: 30px;
}

.contactContainer .contactBox input[type="button"]{
    cursor: pointer;
    margin-top: 10px;
    font-size: 1rem;
    width: 150px;
}

.contact-erreur{
    border: 1px solid red !important;
}

.contactContainer .drop{
    background: rgba(255, 255, 255, .7);
    position: absolute;
    border-left: 1px solid rgba(255, 255, 255, .5);
    border-top: 1px solid rgba(255, 255, 255, .5);
    border-radius: 15px;
    box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, .2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
}

.contactContainer .drop-1{
    height: 150px;
    width: 150px;
    top: -55px;
    left: -55px;
    z-index: -1;
}

.contactContainer .drop-2{
    height: 80px;
    width: 80px;
    bottom: -40px;
    right: -40px;
}

.message-erreur {
    position: fixed;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(107, 107, 107, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    z-index: 1000;
  }
  
// *********************
// **** ERREUR  404 ****
// *********************
.erreur{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 3em;
  }

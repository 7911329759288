// DESCRIPTION à importer
.bandeau-large .bandeau-flexbox .description-container{
    width: 50%; // taille à combiner avec le container du slide dans la flexbox
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px 0;
}

.bandeau-large .bandeau-flexbox .description-container .description-item{
  background-color: white;
  width: 90%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.205);
}

.bandeau-large .bandeau-flexbox .description-container .description-item .description-titre{
  font-weight: bold;
}

.bandeau-large .bandeau-flexbox .description-container .description-item .description-soustitre{
  font-style: italic;
}

.bandeau-large .bandeau-flexbox .description-container .description-item .description-texte{
  padding-left: 15px;
}


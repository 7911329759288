.bandeau-large .bandeau-flexbox .slider-container{
    width: 50%; // taille du container dans la flexbox à combiner avec taille description
    height: 100%;
    position: relative;
    overflow: hidden;
}
.bandeau-large .bandeau-flexbox .slider-container .slider{
    width: 100%; // occupe l'espace du container
    height: 100%; // idem
    display: flex;
    align-items: center;
}

.bandeau-large .bandeau-flexbox .slider .slideritem{
    position: absolute;
    width: 500px; // taille des slides à changer en responsive
    height: 400px; // taille des slides
    background-color: #ffffff;
    border-radius: 10px;
    left: calc(50% - calc(500px/2)); // div2 width
    overflow: hidden;
    transition:  0.5s;
}

.bandeau-large .bandeau-flexbox .slider .sliderimage
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.bandeau-large .bandeau-flexbox .slider .slidertextecontainer{
    display: flex;
    flex-direction: column;
}


.bandeau-large .bandeau-flexbox .slider .slidertextecontainer .slidertitre, .bandeau-large .bandeau-flexbox .slider .slidertextecontainer .slidertexte
{
    color:#fff;
    text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
    z-index: 100;
}

.bandeau-large .bandeau-flexbox .slider .slidertextecontainer .slidertitre
{
    font-size: 25px;
    padding: 20px;
}

.bandeau-large .bandeau-flexbox .slider .slidertextecontainer .slidertexte
{
    padding: 10px;
    font-size: 25px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.925);
}

#slidernext{
    z-index: 60;
    position: absolute;
    right: 10%;
}
#sliderprev{
    z-index: 60;
    position: absolute;
    left: 10%;
}
#sliderprev, #slidernext{
    color: #ffffff;
    background: none;
    border: none;
    font-size: 4em;
    font-family: monospace;
    font-weight: bold;
    opacity: 0.7;
    transition: opacity 0.5s;

}
#sliderprev:hover,
#slidernext:hover{
    opacity: 1;
}
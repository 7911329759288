.navbar-container{
    width: 100%;
    display: flex;
    justify-content: center;
} 

.navbar{ 
    //position: absolute;
    //background-color: rgb(36, 36, 36);
    width: 100%;
    min-height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-family: $font-nav;
    text-align: center;
}

.navbar-logo-titre{
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 20px;
}

.navbar .logo{
    padding-left: 25px;
    font-size: 2em;
    font-weight: bold;
}

.navbar .navbar-titre{
    font-family: $font-footer-logo;
    color: white;
    font-size: 1.3em;
    font-weight: 400;
}


.navbar a{
    color: white;

    &::after{
    display: block;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scale(0);
    transition: transform 0.2s ease-in-out;
    content: '';
    }
}

.navbar .nav-links ul{
    display: flex;
    padding-right: 25px;
}

.navbar .nav-links ul li{
    height: 65px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    transition: 0.3s;

    &:hover a::after {
        transform: scale(1);}
}

.navbar .menu-btn{
    display: none;
    width: 35px;
    position: absolute;
    top: 18px;
    right: 35px;
}

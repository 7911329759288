// *********************
// *** BANDEAU HAUT ***
// *********************
.bandeau {
  height: 400px;
  width: 100%;
  //background: rgba(255, 255, 255, 0.897);
  //box-shadow: 0px 0px 20px 4px #0000009c;
  display: flex;
  justify-content: center;
}

.bandeau-item-photo, .bandeau-item-texte {
  display: flex;
  overflow: hidden;
  color: white;
  justify-content: center;
  align-items: center;
}
.bandeau-item-photo {
  width: 450px;
}

.bandeau-item-texte {
  width: 700px;
  flex-direction: column;
}

.bandeau-photo {
  border: 5px white solid;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  position: absolute;
}

.bandeau-nom {
  font-family: $font-nom;
  font-size: 3.5em;
  font-weight: bold;
}

.bandeau-titre {
  font-family: $font-titre;
  font-size: 3em;
  font-style: italic;
}




// *********************
// *** BANDEAU LARGE ***
// *********************
.bandeau-large {
  min-height: 60vh;
  height: auto;
  //background: rgba(255, 255, 255, 0.897);
  //box-shadow: 0px 0px 20px 4px #0000009c;
  margin: 100px 0;
  padding: 20px 0px 20px 0px;
  display: flex; 
}

.bandeau-large .bandeau-flexbox{
  width: 100%;
  display: flex;
  flex-direction: row; // à changer en reponsive
  justify-content: space-between;
}


.grid-container {
  margin: 0px 10%;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  box-shadow: 0px 0px 20px 4px #0000009a;
}

.grid-container .grid-item {
  width: 100%;
  height: 550px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.grid-container .grid-item a {
  color: black;
  display: block;
  width: 100%;
  height: 100%;

  &:hover {
    .grid-image {
      transform: translate(-80%);
      transition: transform 0.5s ease-in-out;
    }
    .grid-texte {
      opacity: 1;
      transition: opacity 1s;
    }
  }

  &.hovered {
    .grid-image {
      transform: translate(-80%);
      transition: transform 1s ease-in-out;
    }
    .grid-texte {
      opacity: 1;
      transition: opacity 1s;
    }
  }
}

.grid-container .grid-item .grid-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.grid-container .grid-item .grid-titre {
  position: absolute;
  top: 10%;
  font-weight: bold;
  font-size: 1.5rem;
  background-color: white;
  width: 100%; 
  padding-left: 5%;
  box-shadow: 0px 0px 20px 4px #000000;
}

.grid-container .grid-item .grid-texte {
  position: absolute;
  top: 20%;
  left: 25%;
  opacity: 0;
  transition: 0.3s;
}

.hover-message {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(107, 107, 107, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  z-index: 1000;
}

.footer {
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  padding: 30px 30px 20px 30px;
  color: #2f2f2f;
  border-top: 1px solid #e5e5e5;
}
 
.footer > * {
  flex: 1 100%;
}

.footer-apropos-container {
  margin-right: 1.25em;
  margin-bottom: 2em;
}

.footer-apropos-container .footer-logo {
  font-family: $font-footer-logo, cursive;
  font-weight: 400;
  font-size: 1.5rem;
}

.footer-apropos-container h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}


.footer .footer-apropos-texte {
  font-style: normal;
  color: #999;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer-nav {
  display: flex;
  flex-flow: row wrap;
}

.footer-nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
  padding-left: 150px;
}

.footer-nav-titre {
  font-weight: 400;
  font-size: 15px;
}

.footer-nav-ul {
  column-count: 2;
  column-gap: 1.25em;
}

.footer-nav-ul a {
  color: #999;
}


.footer-maj {
  display: flex;
  flex-wrap: wrap;
  color: #999;
}

.footer-lol {
  display: flex;
  align-items: center;
}

.footer-coeur {
  color: #2f2f2f;
}



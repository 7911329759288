// *** RESPONSIVE 1700 PX ***
@media screen and (max-width: 1700px) {
  .grid-container .grid-item .grid-titre{
    font-size: 1.2rem;
  }
}

// *** RESPONSIVE 1350 PX ***
@media screen and (max-width: 1350px) {
  // *** PAGES ***
  // Page Home
  .grid-container{
      grid-template-columns: repeat(2, 1fr);
  }
  // Pages
  .bandeau-large{
    margin-top: 50px;
  }
  .bandeau-large .bandeau-flexbox{
    flex-direction: column;
  }
  .bandeau-large .bandeau-flexbox .slider-container{
    width: 100%;
    height: 350px;
  }
  .bandeau-large .bandeau-flexbox .description-container{
    width: 100%;
  }
  .bandeau-large .bandeau-flexbox .slider .slideritem{
    width: 400px;
    height: 350px;
    left: calc(50% - calc(400px/2)); // div2 width
  }
  .bandeau-large .bandeau-flexbox .slider .slidertextecontainer .slidertitre
  {
      font-size: 20px;
  }

  .bandeau-large .bandeau-flexbox .slider .slidertextecontainer .slidertexte
  {
      font-size: 25px;
  }
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    box-shadow: none;
}
  .grid-container .grid-item {
    max-width: 450px;
    height: 450px;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 4px #0000009a;
  } 
}

// *** RESPONSIVE 1100 PX ***
@media screen and (max-width: 1100px) {
  .navbar-titre{
    display: none;
  }

  .bandeau-photo {
    width: 250px; 
    height: 250px;
  }
  .bandeau-nom {
    font-size: 3em;
  }
  
  .bandeau-titre {
    font-size: 2em;
  }


  .grid-container {
    gap: 10px;
}
  .grid-container .grid-item {
    max-width: 300px;
    height: 300px;
  } 
  .erreur{
    font-size: 2.5em;
  }

}

// *** RESPONSIVE 900 PX ***
@media screen and (max-width: 900px) {
  body{
    background-attachment: unset;
    background-size: cover;
  }

  // *** COMPOSANTS ***
  // Composant Navigation
  .navbar{ 
   position: sticky; 
   top: 0;
   z-index: 500;
  }
  .navbar .logo{
    top: 50px;
    left: 50px;
  }
  .nav-links{
    z-index: 99;
    position: fixed;
    background-color: rgba(95, 94, 94, 0.678);
    backdrop-filter: blur(5px);
    width: 200px;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: all 0.5s ease;
  }
 
  .nav-links.mobile-menu{
    transform: translateX(0%);
  }

  .navbar .nav-links ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .navbar .nav-links ul li{
    margin: 15px 0;
    font-size: 1.2em;
  }

  .navbar .menu-btn{
    z-index: 100;
    display: block;
  }

  .bandeau{
    flex-direction: column;
  }
  .bandeau-item-photo{
    width: 100%;
    height: 300px;
  }
  .bandeau-item-texte {
    width: 100%;
  }

  // *** PAGES ***
  // Page Home
  .grid-container {
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
  .grid-container .grid-item {
    max-width: 400px;
    height: 400px;
  } 

  .contactContainer{
    margin-top: 100px;
  }
  .erreur{
    font-size: 1.7em;
  }

}

// *** RESPONSIVE 600 PX ***
@media screen and (max-width: 600px) {
  .bandeau-photo {
    width: 250px; 
    height: 250px;
  }

  .contactContainer .contactBox textarea{
    width: 300px;
    height: 200px;
  }

  .contactContainer .contactBox form{
    width: 400px;
  }

  .grid-container {
    grid-template-columns: 100%;
    gap: 20px 0;
    justify-items: center; 
  }

  .footer-nav > * {
    padding-left: 0px;
    margin-bottom: 10px;
  }

  .erreur{
    font-size: 1.2em;
  }
}

  

// *** RESPONSIVE 450 PX ***
@media screen and (max-width: 450px) {
  .bandeau-nom {
    font-size: 45px;
  }
  
  .bandeau-titre {
    font-size: 30px;
  }

  .navbar .nav-links ul li{
    margin: 7px 0;
  }

  .bandeau-large{
    margin-top: 0px;
  }

  .bandeau-large .bandeau-flexbox .slider-container{
    height: 400px;
  }

  .bandeau-large .bandeau-flexbox .slider .slideritem{
    width: 300px;
    height: 300px;
    left: calc(50% - calc(300px/2)); // div2 width
  }
  .bandeau-large .bandeau-flexbox .slider .slidertextecontainer .slidertitre
  {
      font-size: 15px;
  }

  .bandeau-large .bandeau-flexbox .slider .slidertextecontainer .slidertexte
  {
      font-size: 15px;
  }

  #slidernext{
    right: 30%;
    bottom: 0;
  }
  #sliderprev{
    left: 30%;
    bottom: 0;
  }

  .contactContainer .contactBox form{
    width: 300px;
  }

  .contactContainer .contactBox input{
    margin: 5px 10px;
  }

  .contactContainer .contactBox textarea{
    width: 250px;
    height: 150px;
  }

  .grid-container .grid-item {
    max-width: 350px;
    height: 350px;
  } 

  .bandeau-nom {
    font-size: 2.2em;
  }
  
  .bandeau-titre {
    font-size: 1.5em;
  }
  .erreur{
    font-size: 0.8em;
  }
}
 



// *** RESPONSIVE FOOTER ***
@media screen and (min-width: 24.375em) {
  .footer-maj .footer-lol {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer-nav > * {
    flex: 1;
  }

  .footer-nav-item {
    flex-grow: 2;
  }

  .footer-apropos-container {
    flex: 1 0px;
  }

  .footer-nav {
    flex: 2 0px;
  }
}
